import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Breadcrumbs from "../components/Breadcrumbs"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { useBreadcrumb } from "gatsby-plugin-breadcrumb"
import SubHero from "../components/SubHero"
import ThreeColumn from "../components/ThreeColumn"
import Accordion from "../components/Accordion"
import CompanyResults from "../components/CompanyResults"

const IndustryTemplate = ({ location }) => {
  const data = useStaticQuery(graphql`
    query IndustryTemplateQuery {
      wpPage(databaseId: { eq: 5420 }) {
        seo {
          title
          metaDesc
          opengraphTitle
          opengraphDescription
          opengraphType
          canonical
        }
        featuredImage {
          node {
            link
          }
        }
        Industry_Template_CF {
          subHeroTitle
          subHeroSubTitle
          subHeroContent
          subHeroImage {
            sourceUrl
          }
          cardHeaderText
          cardMainContent
          backgroundColor
          accordionHeadline
          industryAccordions {
            accordionTitle
            accordionContent
          }
          industryCardsCollection {
            title
            content
            image {
              sourceUrl
            }
          }
        }
      }
      company: allWpCompanyProfile {
        nodes {
          slug
          title
          id
          companyProfiles {
            backgroundColor
            backgroundImage
            backgroundImageAlt
            city
            companyDescription
            companyName
            companySize
            companyUrl
            contactEmail
            fieldGroupName
            industry
            logo
            logoAlt
            logoBackgroundColor
            membershipType
            memberstackId
            perksBenefits
            primaryUser
            phoneNumber
            state
            streetAddress
            zip
          }
        }
      }
    }
  `)
  const industryData = data.wpPage.Industry_Template_CF
  const seoData = data.wpPage.seo
  const { crumbs } = useBreadcrumb({
    location,
    crumbLabel: "Industry",
  })

  const filteredCompany = data.company.nodes.filter((company) => {
    return company.companyProfiles.industry === "Technology"
  })
  return (
    <Layout>
      <Seo title={seoData.title} description={seoData.metaDesc} uri={seoData.canonical} />
      <Breadcrumbs crumbs={crumbs} />
      <SubHero hero={industryData} />
      <ThreeColumn columns={industryData.industryCardsCollection} title={industryData.cardHeaderText} content={industryData.cardMainContent} background={industryData.backgroundColor} />
      <Accordion items={industryData.industryAccordions} headline={industryData.accordionHeadline} />
      <div className="container">
        <CompanyResults posts={filteredCompany} />
      </div>
    </Layout>
  )
}

export default IndustryTemplate
